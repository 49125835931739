import {createApi,  fetchBaseQuery} from '@reduxjs/toolkit/query/react'

const url = `${process.env.REACT_APP_API_URL}/api`;
export const api = createApi({
  reducerPath: 'api',
  tagTypes: ['Products', 'Orders'],
  baseQuery: fetchBaseQuery({
    baseUrl: url
  }),
  endpoints: () => ({}),

})

