import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '@/redux/store'
import { fetchProducts } from "@/redux/thunk/product.thunk"


enum Status {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

interface ProductsSliceState {
  items: [];
  images: [];
  status: 'loading' | 'success' | "error";
  activeCategory: string;
}

const initialState: ProductsSliceState = {
  items: [],
  images: [],
  status: Status.LOADING,
  activeCategory: ''
};

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setActiveCategory: (state, action) => {
      state.activeCategory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.pending, (state) => {
      state.status = 'loading';
    })
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.items = action.payload;
      state.status = 'success';
    })
    builder.addCase(fetchProducts.rejected, (state) => {
      state.status = 'error';
    })

  }

});

export const selectProducts = (state: RootState) => state.products;
export const { setActiveCategory } = productsSlice.actions;
export default productsSlice.reducer;
