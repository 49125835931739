import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

export const fetchProducts = createAsyncThunk('products/fetchProducts', async (_, thunkApi) => {
  try{
    const { data } = await axios(`${process.env.REACT_APP_API_URL}/api/products/getProducts`)
    return data
  }catch(e: any){
    console.log(e.message)
   thunkApi.rejectWithValue(e.message)
  }
 
});