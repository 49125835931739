import React from 'react'


const Arrow:React.FC = () => {
  return (
      <svg width="36" height="15" viewBox="0 0 36 15" xmlns="http://www.w3.org/2000/svg">
      <path d="M35.7071 8.20711C36.0976 7.81658 36.0976 7.18342 35.7071 6.79289L29.3431 0.428932C28.9526 0.0384078 28.3195 0.0384078 27.9289 0.428932C27.5384 0.819457 27.5384 1.45262 27.9289 1.84315L33.5858 7.5L27.9289 13.1569C27.5384 13.5474 27.5384 14.1805 27.9289 14.5711C28.3195 14.9616 28.9526 14.9616 29.3431 14.5711L35.7071 8.20711ZM0 8.5L35 8.5V6.5L0 6.5L0 8.5Z" />
      </svg>

    
  )
}
export default Arrow;