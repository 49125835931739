import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { createLogger } from 'redux-logger'
import products from './slices/productsSlice'
import cart from './slices/cartSlice'
import user from './slices/userSlice'

import {api} from '@/redux/api/api'


const logger = createLogger({
  // ...options
});

export const store = configureStore({
  reducer: {
    products,
    cart,
    user,
    [api.reducerPath]: api.reducer,
   
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware)
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch