import { bindActionCreators } from "@reduxjs/toolkit"
import { useAppDispatch } from "@/redux/store"
import { userSlice } from '@/redux/slices/userSlice'
import { productsSlice } from '@/redux/slices/productsSlice'
import { cartSlice } from '@/redux/slices/cartSlice'

import * as productsThunk from '@/redux/thunk/product.thunk'

const rootActions = {
  ...cartSlice.actions,
  ...productsSlice.actions,
  ...userSlice.actions,
  ...productsThunk

}

export const useActions = (): any => {
  const dispatch = useAppDispatch()
  return bindActionCreators(rootActions, dispatch)
}

