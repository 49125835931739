import React, {useRef, useState} from 'react'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import u1 from "@/assets/img/u1.jpg"
import u2 from "@/assets/img/u2.jpg"
import u3 from "@/assets/img/u3.jpg"
import u4 from "@/assets/img/u4.jpg"
import u5 from "@/assets/img/u5.jpg"

import prev from "@/assets/img/l_prod_slider.svg"
import next from "@/assets/img/r_prod_slider.svg"

import Arrow from '@/Components/Arrow';

import { CustomArrowProps, default as Slider } from "react-slick";
import { Link } from 'react-router-dom';

// import Slider from 'react-slick'





const NextArrow:React.FC<CustomArrowProps> = ({onClick}) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <img src={next} alt="" />
      </div>
    );
  };
  const PrevArrow:React.FC<CustomArrowProps> = ({onClick}) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <img src={prev} alt="" />
      </div>
    );
  };

  type TSliderProps = {
    adaptiveHeight?: false,
    className?: string,
    infinite?: boolean,
    dots?: boolean,
    autoplay?: boolean,
    centerMode?: boolean,
    centerPadding?: string,
    speed?: number,
    autoplaySpeed?: number,
    slidesToScroll?: number,
    slidesToShow?: number,
    nextArrow?: JSX.Element,
    prevArrow?: JSX.Element,
    beforeChange?: (currentSlide: number, nextSlide: number) => void,
    responsive?: { breakpoint: number; settings: any}[]

  }

  


const Unic5Mob:React.FC = () => {
    const [imageIndex, setImageIndex] = useState<number>(0);

    const sliderRef = React.useRef<Slider>(null);

    const settings:TSliderProps = {
        className: 'unic5_carousel',
         infinite: true,
         dots: true,
         speed: 1000,
         autoplay: false,
         autoplaySpeed: 2500,
         slidesToShow: 3,
         slidesToScroll: 1,
         centerMode: false,
         nextArrow: <NextArrow />,
         prevArrow: <PrevArrow />,
         //beforeChange: (current, next) => setImageIndex(next),
         responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 1,
              adaptiveHeight: true,
              // className: "center",
              // slidesToShow: 1,
               centerPadding: "0",
               centerMode: true,
            },
          },
        ],
      };


  return (
   
        <Slider {...settings} ref={sliderRef} >
           
              <div className="unic__Item">
                <p>ГЛОБАЛЬНА ОМОЛОЖДУВАЛЬНА СИСТЕМА</p>
                <img src={u1} alt="" />
                
                <div>Ефективна 3-рівнева процедура для вираженого ліфтингу, миттєвої корекції проявів фото- та хроностаріння, гіперпігментації ...</div>
                <Link to='/' className="moreBtn">ДІЗНАТИСЬ БІЛЬШЕ <Arrow /></Link>
              </div>
              <div className="unic__Item">
                <p>ФІТОАКТИВНИЙ ОКСІПІЛІНГ</p>
                <img src={u2} alt="" />
                
                <div>Передова методика відновлення внутрішньотканинного дихання та функцій клітин шкіри спрямованої дії для корекції жирної та проблемної ...</div>
                <Link to='/' className="moreBtn">ДІЗНАТИСЬ БІЛЬШЕ <Arrow /></Link>
              </div>

              <div className="unic__Item">
                <p>КИСНЕВА ТЕРАПІЯ О2XY</p>
                <img src={u3} alt="" />
               
                <div>An innovative anti-aging treatment based on highly effective botanicals and biomimetic endorphin peptide with Botox-like anti-wrinkle effect</div>
                <Link to='/' className="moreBtn">ДІЗНАТИСЬ БІЛЬШЕ <Arrow /></Link>
              </div>

              <div className="unic__Item">
                <p>ЕКСПРЕС-ПРОЦЕДУРА РЕНЕСАНС</p>
                <img src={u4} alt="" />
                
                <div>Іноваційна антивікова процедура на основі високоефективних рослинних компонентів та біоміметичного ендорфінового пептиду з ботоксоподібною дією проти ...</div>
                <Link to='/' className="moreBtn">ДІЗНАТИСЬ БІЛЬШЕ <Arrow /></Link>
              </div>

              <div className="unic__Item">
                <p>КРИШТАЛЕВА ФЕРМЕНТНА ЧИСТКА</p>
                <img src={u5} alt="" />
                
                <div>Унікальна система ферментативної чистки в формі гелю поєднує в собі комплексну дію 7 ферментів для повноцінного і глибокого очищення шкіри...</div>
                <Link to='/' className="moreBtn">ДІЗНАТИСЬ БІЛЬШЕ <Arrow /></Link>
              </div>
              
        </Slider>
  )
}
export default Unic5Mob;