import React, {useState} from 'react'
import classes from './Promo.module.scss'

import submit_arr from '@/assets/img/submit_arr.svg'

type PromoProps = {
  promoUseFunc:  (promoCode: string) => void
  promoError: boolean
}
const  Promo:React.FC<PromoProps> = ({promoUseFunc, promoError = false}) => {
  
  return (
    <div>
      <form className={classes.promo_form} onSubmit={e=>e.preventDefault()}>
          <span>Промокод</span>
          <input type="text" placeholder='Код' onChange={e => promoUseFunc(e.target.value)} />
          <button type="submit">Застосувати <img src={submit_arr} alt="" /></button>
      </form>
      {promoError && <p className={classes.promoError}>Такого коду не існує!</p>}
    </div>
  )
}

export default Promo
